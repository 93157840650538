import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useApolloClient, gql, useSubscription } from '@apollo/client';
import _ from 'lodash';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import { ToggleButtonGroup, ToggleButton } from '@material-ui/lab';
import { Typography } from '@material-ui/core';
import { NodeListContext } from '.';
import { QueryVariablesContext } from './QueryVariablesContext';
import { useAppSettings } from '../contexts/AppSettingsContext';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  toggleButton: {
    position: 'relative',
  },
  count: {
    position: 'absolute',
    top: -12,
    right: 0,
    borderRadius: '50%',
    backgroundColor: 'hsla(0, 0%, 30%, 100%)',
    color: '#FFF',
    height: '1.8em',
    width: '2em',
    boxShadow: '-2px 2px 4px rgb(0 0 0 / 50%)',
  },
}));

const OptionButton = (props) => {
  const classes = useStyles();
  const { label, option, path, showCount, rootPath, ...toggleButtonProps } = props;
  const queryVariablesContext = useContext(QueryVariablesContext);
  const nodeListContext = useContext(NodeListContext);
  const { type } = nodeListContext || queryVariablesContext;

  const subscriptionDoc = gql`
    subscription{
      count: ${type}_aggregate(where: {${path}: {_eq: "${option.value}"}}){
        aggregate{count}
      }
    }
  `;
  const { data } = useSubscription(subscriptionDoc, { skip: !showCount });
  const count = _.get(data, 'count.aggregate.count');

  return (
    <ToggleButton value={option.id || option.value} className={classes.toggleButton} {...toggleButtonProps}>
      {label}
      {showCount && <div className={classes.count}>{count}</div>}
    </ToggleButton>
  );
};

const ToggleButtonOptionsFilter = (props) => {
  const { className, showCount, options: propsOptions, label, path, rootPath, ...nodeSelectorProps } = props;
  const queryVariablesContext = useContext(QueryVariablesContext);
  const { options: appSettingsOptions } = useAppSettings();
  const nodeListContext = useContext(NodeListContext);
  const { updateVariables, variables, schemaType, type } = nodeListContext || queryVariablesContext;
  let options = propsOptions || _.get(schemaType.fields[path], 'options');
  const optionsName = _.get(schemaType.fields[path], 'optionsName');
  if (!options && optionsName) {
    options = appSettingsOptions[optionsName];
  }
  const fullPath = `${rootPath}${rootPath && '.'}${path}`;

  if (!options) {
    console.error(`ToggleButtonOptionsFilter cannot find options for ${type}.${path}.`);
    options = [];
  }

  const handleChange = (event, value) => {
    if (!value) value = [];
    if (!value.length) value = undefined;

    if (value) {
      updateVariables({ path: `${fullPath}._in`, value });
    } else {
      updateVariables({ path: `${fullPath}._in`, value: undefined });
    }
  };

  const clear = (event) => {
    handleChange([]);
  };

  const classes = useStyles();
  const valueIds = _.get(variables, `${fullPath}._in`, []);

  return (
    <div className={classNames(classes.root, className)}>
      {label && (
        <Typography variant="body1" color="textSecondary">
          {label}
        &nbsp;&nbsp;
        </Typography>
      )}
      <ToggleButtonGroup value={valueIds} onChange={handleChange} size="small">
        {options.map((option) => {
          if (!option) return null;
          const buttonLabel = option.primary || option.label;
          if (!buttonLabel) return null;
          return (
            <OptionButton
              value={option.id || option.value}
              key={option.id || option.value}
              option={option}
              label={buttonLabel}
              path={path}
              rootPath={rootPath}
              showCount={showCount}
            />
          );
        })}
      </ToggleButtonGroup>
    </div>
  );
};

ToggleButtonOptionsFilter.propTypes = {
  label: PropTypes.string,
  className: PropTypes.string,
  path: PropTypes.string.isRequired,
  rootPath: PropTypes.oneOf(['where', 'args.where']),
};

ToggleButtonOptionsFilter.defaultProps = {
  label: 'Is one of these:',
  className: '',
  rootPath: 'where',
};

export { ToggleButtonOptionsFilter };
