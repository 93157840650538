import { fragments } from './fragments';

export default {
  types: {
    option: {

      selections: {
        default: `
          id primary secondary name data weight value
        `,
      },

      fields: {
        primary: { type: 'String', label: 'Label', validation: { required: true } },
        secondary: { type: 'String', label: 'Description', validation: { required: false } },
        data: { type: 'Json', validation: { required: false } },
        weight: { type: 'Float', defaultValue: 0, validation: { required: false } },
        name: {
          type: 'String',
          label: 'Data Field',
          validation: { required: true },
          options: [
            { value: 'job-status', primary: 'Job Status' },
            { value: 'job-type', primary: 'Job Type' },
            // { value: 'yard', primary: 'Yard' },
          ],
        },
      },
    },
  },
};
