import React, { useMemo, forwardRef, useContext } from 'react';
import { Typography, InputAdornment, Link } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useTheme } from '@material-ui/styles';
import { Grid } from 'src/kiska/components/Grid';
import CustomerIcon from 'mdi-material-ui/AccountTie';
import { usePrint } from 'src/kiska/components/PdfGenerator';
import { GridLineBreak } from 'src/components/forms';
import { FormSection } from 'src/components/forms/FormSection';
import { TextField, FileField, NodeField, BooleanField, DateTimeField, SelectField } from 'src/kiska/components/ViewOrUpdateFields';
import { useViewOrUpdate } from 'src/kiska/components/useViewOrUpdate';
import EditButton from 'src/components/updates/EditButton';
import { SimpleTable } from 'src/components/dashboards/settings/SimpleTable';
import WorkerIcon from 'mdi-material-ui/AccountHardHat';
import ChangeOrderIcon from 'mdi-material-ui/ArrowDecision';
import { AttachFile as FilesIcon } from '@material-ui/icons';
import MedicalIcon from 'mdi-material-ui/Ambulance';
import _ from 'lodash';
import { useNodes } from 'src/kiska/hooks/useNode';
import Fixture from 'src/kiska/components/MutationContext/FormFields/Fixture';
import { useAppSettings } from 'src/kiska/components/contexts/AppSettingsContext';
import { ReportTitle } from 'src/components/dashboards/ReportTitle';
import { useSchema } from 'src/kiska/components/contexts/SchemaContext';
import { useMutationContext } from 'src/kiska/components/MutationContext/MutationContext';
import { CustomerUpdateForm } from '../customer/CustomerUpdateForm';
import { Hideable } from '../../../kiska/components/Hideable';
import { useLocalNode } from '../../../kiska/components/LocalNodeContext';
import { generateShortId } from './utils';
import { ContactRenderer } from '../customer/ContactRenderer';
import { ElectricalJobForm } from './job_types/ElectricalJobForm';
import { useChangeOrderColumns } from '../form/forms/useChangeOrdersColumns';
import { FormViewDialog } from '../form/FormViewDialog';
import { forms } from '../form/forms';
import { useSafetyRecordColumns } from '../form/forms/useSafetyRecordColumns';
import { Calendar } from '../calendar/Calendar';
import { usePrevDailySafetyFormValues } from '../form/forms/usePrevDailySafetyFormValues';

export const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4, 2),
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(2, 1, 2, 1),
    },
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(2, 2, 2, 2),
    },
    // margin: theme.spacing(-0, -2, -2, -2),
  },
  dialogContent: {
    padding: 0,
  },
  header: {
    // marginBottom: theme.spacing(-4),
  },
  customerViewRenderer: {
    width: '100%',
  },
  listFormSectionContent: {
    padding: theme.spacing(2, 2, 2, 2),
  },
  calendarFormSectionContent: {
    padding: theme.spacing(0, 1, 2, 1),
  },
  titleUpdateWrapper: {
    padding: theme.spacing(0, 2),
  },
  updateDeliverAtRoot: {
    display: 'flex',
    justifyContent: 'center',
    padding: theme.spacing(0, 0, 2, 0),
  },
}));

const stdWidths = { xs: 12, sm: 12, md: 6, lg: 3 };

const CustomerViewRenderer = ({ node }) => {
  const { printMode } = usePrint();
  const classes = useStyles();
  const { id } = node || {};

  return (
    <div className={classes.customerViewRenderer}>
      <ContactRenderer contact={node} titleProps={{ variant: printMode ? 'body1' : 'h6' }} />
      {!printMode && (
        <Grid container justify="flex-end">
          <CustomerUpdateForm
            id={id}
            trigger={<EditButton icon={CustomerIcon} color="primary" size="small" variant="contained" style={{ marginBottom: 8 }}>Edit Customer</EditButton>}
          />
        </Grid>
      )}
    </div>
  );
};

const JobForm = forwardRef((props, ref) => {
  const { appSettings, getOption } = useAppSettings();
  const { variant } = props;
  const theme = useTheme();
  const classes = useStyles();
  const { view, update } = useViewOrUpdate();
  const { node } = useLocalNode();
  const job = node;
  const isNew = !node || !node.id;
  const { printMode } = usePrint();
  const initialShortId = useMemo(generateShortId, [node && node.id]);
  const { columns: changeOrderColumns } = useChangeOrderColumns({});
  const { columns: dailySafetyColumns } = useSafetyRecordColumns({});
  const { nodes: tasks } = useNodes({ type: 'task' });
  const { initialValues: safetyFormInitialValues } = usePrevDailySafetyFormValues({ job });
  const mutationContext = useMutationContext();

  if (!tasks.length) return null;

  const basicFieldsFilled = () => {
    return job.title && job.type && job.customer && _.get(job, 'specs.callType');
  };

  const type = update ? mutationContext.getFieldValue('type') : job.type;

  const handleCustomerChanged = (name, value) => {
    if (!value) return;

    if (value.address1) {
      const deliveryAddress = [
        value.address1,
        value.address2,
        value.city,
      ].filter((s) => !!s).join('\n');
      mutationContext.onChange('specs.deliveryAddress', deliveryAddress);
    }
    if (value.phone) {
      mutationContext.onChange('specs.deliveryPhone', value.phone);
    }
  };

  if (variant === 'update-deliver-at') {
    return (
      <div className={classes.updateDeliverAtRoot}>
        <DateTimeField static name="deliverAt" label="Delivery Time & Date" pickerProps={{ openTo: 'hours' }} />
      </div>
    );
  }

  return (
    <div className={classes.root} ref={ref} style={{ paddingBottom: update ? '48px' : undefined }}>
      {appSettings.data.jobs.allJobsAllTasks && update && <Fixture name="tasks" value={tasks} />}

      {update && (
        <>
          <Fixture name="shortId" value={initialShortId} />
        </>
      )}

      {view && printMode && (
        <ReportTitle title={job.title} />
      )}

      <Grid container spacing={4}>
        {update && (
          <Grid item xs={12} md={12}>
            <TextField name="title" />
          </Grid>
        )}

        <Grid item xs={12} md={8}>
          <NodeField
            name="customer"
            label="Customer"
            creatableUpdateForm={CustomerUpdateForm}
            creatableInputFieldMapping="company"
            viewRenderer={CustomerViewRenderer}
            onChange={handleCustomerChanged}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <SelectField name="type" label="Job Type" />
        </Grid>

        <Grid item xs={12} md={6}>
          <SelectField name="status" label="Status" />
        </Grid>

        <Grid item xs={12} md={12}>
          <NodeField name="tasks" initialValue={tasks} />
        </Grid>

        <GridLineBreak />

        <Grid item xs={12} md={12} lg={12}>
          <TextField name="specs.notes" label="Notes" rows={10} />
        </Grid>
      </Grid>

      <br />
      <FormSection title={`Files (${isNew ? 0 : job.files.length})`} icon={FilesIcon} initiallyExpanded={false}>
        <Grid item xs={12}>
          <FileField name="files" label="" />
        </Grid>
      </FormSection>
    </div>
  );
});

JobForm.displayName = 'JobForm';

export { JobForm };
