import React from 'react';
import { fragments } from './fragments';
import { yesNoOptions } from './utils';

export default {
  types: {
    job: {
      renderers: {
        inline: ({ node }) => <>{node.shortId} - {node.title}</>,
      },

      selections: {
        default: `
          ${fragments.job.basic}
          tasks { id task {
            ${fragments.task.basic}
          }}
          files(order_by: {file: {updatedAt: desc}}) { id file {
            ${fragments.file.basic}
          }}
          forms {id form{id title jobs {id job{id}}}}
          tags {id tag {id label type color icon}}
          firstWorkEntry: workEntries(limit: 1, order_by: [{start: asc}]){
            start end
            user {
              id displayName email
              avatar {id url mimeType name }
            }
          }
          lastWorkEntry: workEntries(limit: 1, order_by: [{start: desc}]){
            start end
            user {
              id displayName email
              avatar {id url mimeType name }
            }
          }
          workEntries_aggregate {
            aggregate {
              sum {
                duration
              }
            }
          }
          assignedUsers {id user {
            id displayName email
            avatar {id url mimeType name }
          }}
          notedWorkEntries: workEntries(where: {notes: { _neq: "" }}){
            id start end duration notes
            user {id displayName avatar{id url}}
          }
          expenses_aggregate{aggregate{count}}
          change_orders_aggregate: forms_aggregate(where: {form: {name:{_eq: "ChangeOrderForm"}}}){aggregate{count}}
          resources{id resource{id title description link createdAt updatedAt userId expires files}}
          resources_aggregate{aggregate{count}}
        `,
      },

      fields: {
        files: {
          type: 'file',
          hasMany: true,
        },

        shortId: {
          type: 'String',
          label: 'Code',
          validation: {
            required: true,
          },
        },

        title: {
          type: 'String',
          validation: {
            required: true,
          },
        },

        options: {
          type: 'Json',
        },

        specs: {
          type: 'Json',
        },

        customer: {
          type: 'contact',
          validation: {
            required: true,
          },
        },

        tasks: {
          type: 'task',
          hasMany: true,
          validation: {
            required: true,
          },
        },

        tags: {
          type: 'tag',
          hasMany: true,
          validation: { required: false },
        },

        forms: {
          type: 'form',
          hasMany: true,
          validation: { required: false },
        },

        assignedUsers: {
          type: 'user',
          hasMany: true,
          validation: { required: false },
        },

        resources: {
          type: 'resource',
          hasMany: true,
          validation: { required: false },
        },

        status: {
          type: 'String',
          optionsName: 'job-status',
          validation: {
            required: true,
          },
        },

        type: {
          type: 'String',
          validation: { required: true },
          optionsName: 'job-type',
        },

        'specs.billingInfoSameAsCustomer': { type: 'String', options: yesNoOptions, defaultValue: 'yes', validation: { required: true } },
        'specs.billingInfo': { type: 'String', validation: { requiredIf: { dep: 'specs.billingInfoSameAsCustomer', eq: 'no' } } },
        'specs.billStatus': { type: 'String', defaultValue: 'not-billed', validation: { required: true } },

        'specs.notes': { type: 'String', validation: { required: false } },
      },
    },
  },
};
